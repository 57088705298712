const paragraphProductPage = (text) => {
  text
    .split(`\\`)
    .map((paragraph) => `<p>${paragraph.replace(/\\|\n/g, ``)}</p>`)
    .filter((item, index) => index % 2 === 0)
    .join(``);
  return text.replace(/[*#]{2,3}/g, ``).replace(/\\\n/g, " ");
};

export { paragraphProductPage };
