import { useEffect, useLayoutEffect, useRef, useState } from "react";

const useSlider = cardsContainer => {
  const [{ nextCard, previousCard }, setCards] = useState({
    nextCard: null,
    previousCard: null
  });

  const cards = useRef([]);
  const [offsetWidth, setOffsetWidth] = useState(0);

  useLayoutEffect(() => {
    const handleResize = () => {
      if (!cardsContainer.current) return;
      cards.current = Array.from(cardsContainer.current.children);

      setOffsetWidth(cards.current[0] ? cards.current[0].offsetLeft : 0);
    };

    handleResize();
    window.addEventListener("resize", handleResize, { passive: true });
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!cardsContainer.current || !offsetWidth) return;
    const cardsContainerElement = cardsContainer.current;

    const calculateCards = () => {
      const scrollRight =
        cardsContainerElement.scrollWidth -
        cardsContainerElement.scrollLeft -
        cardsContainerElement.clientWidth;

      const nextCard =
        scrollRight !== 0
          ? cards.current.find(
              card =>
                card.offsetLeft >
                cardsContainerElement.scrollLeft + Math.ceil(offsetWidth)
            )
          : undefined;
      const previousCard = cards.current
        .slice()
        .reverse()
        .find(
          card =>
            card.offsetLeft <
            cardsContainerElement.scrollLeft + Math.floor(offsetWidth)
        );

      setCards({ nextCard, previousCard });
    };

    calculateCards();
    window.addEventListener("resize", calculateCards, { passive: true });
    cardsContainerElement.addEventListener("scroll", calculateCards, {
      passive: true
    });
    return () => {
      window.removeEventListener("resize", calculateCards);
      cardsContainerElement.removeEventListener("scroll", calculateCards);
    };
  }, [offsetWidth]);

  const scrollToCard = card => {
    if (!card || !cardsContainer.current) return;

    cardsContainer.current.scrollTo({
      top: 0,
      left: card.offsetLeft - offsetWidth,
      behavior: "smooth"
    });
  };

  const handleNext = () => scrollToCard(nextCard);
  const handlePrevious = () => scrollToCard(previousCard);

  return {
    isNextCard: !!nextCard,
    isPreviousCard: !!previousCard,
    onNext: handleNext,
    onPrevious: handlePrevious
  };
};

export { useSlider };
