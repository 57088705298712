import { navigate } from "@reach/router";
import React from "react";
import { Arrow } from "./Arrow";
import "./styles.css";

export const Breadcrumbs = ({ breadcrumbs }) => {
  const HandlerNavigate = (e, link) => {
    e.preventDefault();

    if (link === "/") {
      navigate(link);
    } else if (link === "/catalog") {
      navigate("/catalog");
    }
  };
  return (
    <nav className="Breadcrumbs">
      <ul className="Breadcrumbs__list">
        {breadcrumbs.map(({ breadcrumb, link }, index) => (
          <React.Fragment key={index}>
            <a onClick={(e) => HandlerNavigate(e, link)} href={link}>
              <li
                className="[ Breadcrumbs__link ] [ Font_smallText ]"
                key={link}
              >
                {breadcrumb}
              </li>
            </a>

            {link && <Arrow className="Breadcrumbs__arrow" />}
          </React.Fragment>
        ))}
      </ul>
    </nav>
  );
};
