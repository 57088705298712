import React from "react";
import { Image } from "../../Image";
import { Arrow } from "./Arrow";
import "./styles.css";
import { useGallary } from "./useGallary";

export const Gallary = ({ images }) => {
  const {
    gallaryContainers: {
      slidesContainer,
      slidesPaginationContainer,
      slidesPaginationMobileContainer,
    },
    gallaryHandlers: {
      scrollTo,
      slideBack,
      slideNext,
      handleTouchStart,
      handleTouchMove,
    },
    paginationButton: { leftButton, rightButton },
  } = useGallary(images);

  return (
    <div className="Gallary">
      <div className="Gallary__slider">
        <div ref={slidesContainer} className="Gallary__slides">
          {images.map((image, index) => (
            <div
              className="Gallary__slide  Galary__slide_big"
              data-index={index}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              key={index}
            >
              <Image className="Gallary__image" image={image} alt="" />
            </div>
          ))}
        </div>

        <div className="Gallary__paginationMobile">
          <div
            ref={slidesPaginationMobileContainer}
            className="Gallary__slides  Gallary__slides_paginationMobile"
          >
            {images.map((_, index) => (
              <button
                data-index={index}
                className="Gallary__paginationMobileControl"
                onClick={scrollTo.bind(this, index)}
                key={index}
              ></button>
            ))}
          </div>
        </div>
      </div>

      <div className="Gallary__pagination">
        <button
          ref={leftButton}
          onClick={slideBack}
          className="Gallary__paginationButton"
        >
          <Arrow className="Gallary__paginationButton_back" />
        </button>
        <div ref={slidesPaginationContainer} className="Gallary__slides">
          {images.map((image, index) => (
            <React.Fragment key={index}>
              <button
                className="Gallary__paginationControll"
                onClick={scrollTo.bind(this, index)}
              >
                <Image
                  className="[ Gallary__image ] 
                  [ Gallary__imagePagination ]"
                  image={image}
                  alt=""
                />
              </button>
            </React.Fragment>
          ))}
        </div>
        <button
          ref={rightButton}
          onClick={slideNext}
          className="Gallary__paginationButton"
        >
          <Arrow className="Gallary__paginationButton_next" />
        </button>
      </div>
    </div>
  );
};
