import cn from "classnames";
import React from "react";
import "./styles.css";
export const Tag = ({ tagName, className, discoutValue }) => {
  const classNameTag = cn({
    [className]: className,
  });

  return (
    <li className={classNameTag}>
      {tagName === "leaderOfSales" && <div>Лидер продаж</div>}
      {tagName === "newProduct" && <div>Новинка</div>}
      {tagName === "discount" && <div>{discoutValue}</div>}
    </li>
  );
};
