import React from "react";

export const Arrow = (props) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.9297 4.92969L12.4297 6.42969L17 11H2.92969V13H17L12.4297 17.5703L13.9297 19.0703L21 12L13.9297 4.92969Z" />
    </svg>
  );
};
