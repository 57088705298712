import React from "react";
import { Arrow } from "../../../icons/Arrow";
import "./styles.css";

const SliderNavigation = ({ isNext, isPrevious, onNext, onPrevious }) => {
  return (
    <div className="SliderNavigation">
      <button
        className="SliderNavigation__button"
        disabled={!isPrevious}
        onClick={onPrevious}
        label="Предыдущий слайд"
      >
        <div>
          <Arrow />
        </div>
      </button>
      <button
        className="SliderNavigation__button"
        disabled={!isNext}
        onClick={onNext}
        label="Следующий слайд"
      >
        <div>
          <Arrow />
        </div>
      </button>
    </div>
  );
};

export { SliderNavigation };
