import React from "react";
import cn from "classnames";

import "./styles.css";

const SliderCard = ({ className, classNameContent, children }) => {
  return (
    <div className={cn("[ SliderCard ]", className)}>
      <div className={cn("[ SliderCard__content ]", classNameContent)}>
        {children}
      </div>
    </div>
  );
};

export { SliderCard };
