import React, { useState } from "react";
import { paragraphProductPage } from "../../../utils/paragraphProductPage";
import { Arrow } from "./Arrow";
import cn from "classnames";
import "./styles.css";

export const ProductDescription = ({ description }) => {
  const [isOpen, setIsOpen] = useState(false);
  const classnamesDescription = cn({
    ProductProduct_productDescription: true,
    ProductProduct_productDescription_open: isOpen,
  });
  const classnameButton = cn({
    ProductDescription__readMoreButton_open: isOpen,
  });

  const changeDescriptionSize = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div
        className={` [ ${classnamesDescription} ]
          [ Color_darkBlue80 Font_textRegular ]`}
        dangerouslySetInnerHTML={{
          __html: paragraphProductPage(description),
        }}
      />
      <button
        onClick={changeDescriptionSize}
        className=" [ ProductProduct_readMore ] [ Font_smallText Color_blue ]"
      >
        {isOpen ? "Свернуть" : "Развернуть"}
        <Arrow
          className={`[ ProductDescription__readMoreButton ]  [ ${classnameButton} ]`}
        />
      </button>
    </>
  );
};
