import React from "react";
import cn from "classnames";
const SliderCardImage = ({ productPage, children }) => {
  const SliderCardImageProduct = cn({
    ProductSimilarProducts__sliderCardImage: productPage,
  });
  const SliderCardImage__containerProduct = cn({
    ProductSimilarProducts__sliderCardContainerProduct: productPage,
  });

  return (
    <div className={`[ SliderCardImage ]  [ ${SliderCardImageProduct} ]`}>
      <div
        className={` [ SliderCardImage__container ]
          ${SliderCardImage__containerProduct}`}
      >
        {children}
      </div>
    </div>
  );
};

export { SliderCardImage };
