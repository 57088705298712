export const touchsEventHandler = ({ slidesPaginationMobile }) => {
  const getIndexes = (evt) => {
    let currentIndex = +evt.target.closest(".Gallary__slide").dataset.index;
    let prewIndex = currentIndex === 0 ? 0 : currentIndex - 1;
    let nextIndex =
      currentIndex === slidesPaginationMobile.length - 1
        ? currentIndex
        : currentIndex + 1;

    return { prewIndex, currentIndex, nextIndex };
  };

  const buttonWidth = slidesPaginationMobile
    ? slidesPaginationMobile[0].offsetWidth
    : null;

  /* Touch event switch mobile pagination button */
  const getTouches = (evt) => {
    return (
      evt.touches || evt.originalEvent.touches // browser API
    ); // jQuery
  };
  let xDown = null;
  let yDown = null;

  const checkSwipe = (evt) => {
    if (!xDown || !yDown) {
      return;
    }

    let xUp = evt.touches[0].clientX;
    let yUp = evt.touches[0].clientY;

    let xDiff = xDown - xUp;
    let yDiff = yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      /*most significant*/
      if (xDiff > 0) {
        /* left swipe */
        return true;
      } else {
        /* right swipe */
        return false;
      }
    }

    xDown = null;
    yDown = null;
  };

  const handleTouchStart = (evt) => {
    const firstTouch = getTouches(evt)[0];
    xDown = firstTouch.clientX;
    yDown = firstTouch.clientY;
  };

  const handleTouchMove = (evt) => {
    const { prewIndex, nextIndex } = getIndexes(evt);

    slidesPaginationMobile.forEach((elem, index) => {
      if (checkSwipe(evt)) {
        if (index === nextIndex) {
          elem.classList.add("Gallary__paginationMobileControl__active");
          elem.focus({ preventScroll: true });
        } else {
          elem.classList.remove("Gallary__paginationMobileControl__active");
        }
      } else {
        if (index === prewIndex) {
          elem.classList.add("Gallary__paginationMobileControl__active");
          elem.focus({ preventScroll: true });
        } else {
          elem.classList.remove("Gallary__paginationMobileControl__active");
        }
      }
    });

    if (checkSwipe(evt)) {
      slidesPaginationMobile[0].style.marginLeft = `-${buttonWidth *
        nextIndex}px`;
    } else {
      slidesPaginationMobile[0].style.marginLeft = `-${buttonWidth *
        prewIndex}px`;
    }
  };

  return { handleTouchStart, handleTouchMove };
};
