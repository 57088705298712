import { Link } from "gatsby";
import React from "react";
import { Arrow } from "../../../icons/Arrow";
import { Image } from "../../Image";
import { Button } from "./../../Button/Button";
import "./styles.css";

export const Solution = ({ name, subtitle, link, background, className }) => {
  return (
    <Link to={link}>
      <div className="[ Solution ]">
        <p className="Solution__blockName">{name}</p>

        <div className="[ Solution__content ] [ Content md:Content_small sm:Content_medium ]">
          <div className="Solution__info">
            {/* <p className="Solution__blockName">{name}</p> */}
            <h3 className="[ Font_headerMiddle sm:Font_headerSmall Color_darkBlue80 ]">
              {subtitle}
            </h3>
            <Button className="Solution__button" rightIcon={<Arrow />}>
              Узнать больше
            </Button>
          </div>

          <div className="Solution__imageContainer">
            <Image className="Solution__image" image={background} alt="" />
          </div>
        </div>
      </div>
    </Link>
  );
};
