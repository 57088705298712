import { useEffect } from "react";

const useFocusFirstFocusable = (elementRef, focus) => {
  useEffect(() => {
    if (!focus || !elementRef.current) {
      return;
    }

    const focusableElements = elementRef.current.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );

    const firstFocusable = focusableElements[0];

    if (firstFocusable && typeof firstFocusable.focus === "function") {
      firstFocusable.focus();
    }
  }, [elementRef, focus]);
};

export { useFocusFirstFocusable };
