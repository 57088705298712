/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { touchsEventHandler } from "./touchsEventHandler";

const useGallary = (images, settings) => {
  const slidesContainer = useRef(null);
  const [slides, setSlides] = useState(null);

  const slidesPaginationContainer = useRef();
  const [slidesPagination, setSlidesPagination] = useState(null);

  const slidesPaginationMobileContainer = useRef(null);
  const [slidesPaginationMobile, setSlidesPaginationMobile] = useState(null);

  const leftButton = useRef();
  const rightButton = useRef();

  //global index state
  const [{ oldIndex, currentIndex }, setIndex] = useState({
    oldIndex: 1,
    currentIndex: 0,
  });

  // set target element focus
  const setFocusPaginationImage = useCallback(() => {
    if (slidesPagination && slidesPaginationMobile) {
      slidesPagination[currentIndex].firstChild.classList.add(
        "Gallary__imagePagination_active"
      );
      slidesPagination[oldIndex].firstChild.classList.remove(
        "Gallary__imagePagination_active"
      );
      slidesPaginationMobile[currentIndex].classList.add(
        "Gallary__paginationMobileControl__active"
      );

      slidesPaginationMobile[oldIndex].classList.remove(
        "Gallary__paginationMobileControl__active"
      );
    }
  }, [currentIndex, oldIndex, slidesPagination, slidesPaginationMobile]);

  // scroll configurations
  const scroll = (el) => {
    el.scrollIntoView({
      behavior: "smooth", // scrolling element
      block: "nearest", //  disabled scroll  page to top
      inline: "nearest", // disabled scroll page to left
    });
  };

  // initialize slides containers
  useEffect(() => {
    setSlides(() => [...slidesContainer.current.children]);
    setSlidesPagination(() => [...slidesPaginationContainer.current.children]);

    setSlidesPaginationMobile(() => [
      ...slidesPaginationMobileContainer.current.children,
    ]);

    leftButton.current.disabled = true;
  }, [
    slidesContainer,
    slidesPaginationContainer,
    slidesPaginationMobileContainer,
  ]);

  // observe (set disabled arrow button, change focus target element)
  useEffect(() => {
    if (oldIndex !== null) {
      setFocusPaginationImage();
    } else {
      slidesPagination.forEach((slides, index) => {
        index === currentIndex
          ? slides.firstChild.classList.add("Gallary__imagePagination_active")
          : slides.firstChild.classList.remove(
              "Gallary__imagePagination_active"
            );
      });

      slidesPaginationMobile.forEach((slides, index) => {
        index === currentIndex
          ? slides.classList.add("Gallary__paginationMobileControl__active")
          : slides.classList.remove("Gallary__paginationMobileControl__active");
      });
    }

    const setDisabledButton = () => {
      if (currentIndex !== 0) {
        leftButton.current.disabled = false;
      } else {
        leftButton.current.disabled = true;
      }

      if (currentIndex !== slidesPagination?.length - 1) {
        rightButton.current.disabled = false;
      } else {
        rightButton.current.disabled = true;
      }
    };
    setDisabledButton();
  }, [
    slidesPagination,
    currentIndex,
    oldIndex,
    setFocusPaginationImage,
    slidesPaginationMobile,
  ]);

  // scrolling function
  const scrollTo = useCallback(
    (index) => {
      setIndex({ currentIndex: index, oldIndex: null });
      scroll(slides[index]);
    },

    [slides]
  );

  /* only one element can scrolling at the same time  
becourse we need setTimeOut  */

  const slideBack = useCallback(() => {
    if (currentIndex > 0) {
      setTimeout(() => {
        scroll(slides[currentIndex - 1]);
      }, 220);

      scroll(slidesPagination[currentIndex - 1]);

      setIndex({ oldIndex: currentIndex, currentIndex: currentIndex - 1 });
    }
  }, [currentIndex, slides, slidesPagination]);

  const slideNext = useCallback(() => {
    if (currentIndex < slides.length - 1) {
      setTimeout(() => {
        scroll(slides[currentIndex + 1]);
      }, 220);
      scroll(slidesPagination[currentIndex + 1]);

      setIndex({ oldIndex: currentIndex, currentIndex: currentIndex + 1 });
    }
  }, [currentIndex, slides, slidesPagination]);

  const { handleTouchStart, handleTouchMove } = touchsEventHandler({
    slidesPaginationMobile,
  });

  const result = useMemo(
    () => ({
      gallaryContainers: {
        slidesContainer,
        slidesPaginationContainer,
        slidesPaginationMobileContainer,
      },
      gallaryHandlers: {
        scrollTo,
        slideBack,
        slideNext,
        handleTouchStart,
        handleTouchMove,
      },
      paginationButton: { leftButton, rightButton },
    }),
    [handleTouchMove, handleTouchStart, scrollTo, slideBack, slideNext]
  );
  return result;
};

export { useGallary };
