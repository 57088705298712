import { graphql } from "gatsby";
import React, { useState, useCallback } from "react";
import { Button } from "./../Button/Button";
import { Breadcrumbs } from "./Breadcrumbs";
import { Gallary } from "./Gallary";
import { ProductDescription } from "./ProductDescription/ProductDescription";
import "./styles.css";
import { Tags } from "./Tags";
import { ContactUsModal } from "./../ContactUsModal/ContactUsModal";
import { Form } from "./../Form";

const ProductProduct = ({
  buttonLabel,
  selectedProduct: {
    name,
    oldPrice,
    price,
    category,
    additionalCriteria,
    description,
    photos,
  },
}) => {
  photos = photos.concat(photos);

  const breadcrumbs = [
    { breadcrumb: "Главная", link: "/" },
    { breadcrumb: "Каталог", link: "/catalog" },
    { breadcrumb: "Детали товара", link: null },
  ];

  const [isOpenContactUsModal, setIsOpenContactUsModal] = useState();
  const handleOpenContactUsModal = useCallback(
    () => setIsOpenContactUsModal(true),
    []
  );
  const handleCloseContactUsModal = useCallback(
    () => setIsOpenContactUsModal(false),
    []
  );

  return (
    <div className="[ ProductProduct ] [ Content md:Content_small  sm:Content_medium ] ">
      <Breadcrumbs breadcrumbs={breadcrumbs} />

      <div className="ProductProduct__container">
        <Gallary className="[ ProductProduct__gallery ]" images={photos} />

        <div className="ProductProduct__info">
          <div className="ProductProduct__categoryTag ">{category}</div>
          <div className="[ ProductProduct__productName ] [ Font_headerMiddle md:Font_headerSmall sm:Font_smallText Color_darkBlue ]">
            {name}
          </div>

          <div className="ProductProduct__tagPricesWrapper">
            <Tags tags={additionalCriteria} />

            <ul className="ProductProduct__prices">
              {oldPrice && (
                <li className="[ ProductProduct__priceOld ] [ Font_headerSmallMiddle  sm:Font_headerSmallest ]">
                  {oldPrice}
                </li>
              )}
              <li className=" [ ProductProduct__price ] [ Font_headerMiddle sm:Font_headerSmall  ]">
                {price}
              </li>
            </ul>
          </div>

          <ProductDescription description={description} />

          <Button
            className="ProductProduct_productOrder"
            onClick={handleOpenContactUsModal}
          >
            {buttonLabel}
          </Button>

          <ContactUsModal
            isOpen={isOpenContactUsModal}
            onClose={handleCloseContactUsModal}
          >
            <Form colorTheme="white" />
          </ContactUsModal>
        </div>
      </div>
    </div>
  );
};

ProductProduct.getLivePreviewData = ({ data, getAsset }) => ({
  product: {
    ...data.product,
  },
});

const productProductQuery = graphql`
  fragment ProductProductFragment on MarkdownRemarkFrontmatter {
    product {
      buttonLabel
    }
  }
`;

export { ProductProduct, productProductQuery };
