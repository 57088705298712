import React, { useRef } from "react";
import { SliderNavigation } from "./SliderNavigation";
import "./styles.css";
import { useSlider } from "./useSlider";
import cn from "classnames";

const Slider = ({ className, productPage, children }) => {
  const cardsContainer = useRef();
  const { isNextCard, isPreviousCard, onNext, onPrevious } = useSlider(
    cardsContainer
  );
  const sliderClassName = cn({
    FullBleed_scroll: !productPage,
    Slider__cards: !productPage,

    ProductSimilarProducts__SliderCards: productPage,
    FullBleed_scrollProductPage: productPage,
  });

  return (
    <div className={className}>
      <SliderNavigation
        isNext={isNextCard}
        isPrevious={isPreviousCard}
        onNext={onNext}
        onPrevious={onPrevious}
      />

      <div
        className={`[ ${sliderClassName} ]
        [ FullBleed  ]`}
        ref={cardsContainer}
      >
        {children}
      </div>
    </div>
  );
};

export { Slider };
