import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { useOnClickOutside } from "./useOnClickOutside";
import { useFocusFirstFocusable } from "./useFocusFirstFocusable";
import { Close } from "../../icons/Close";

import "./styles.css";

const Modal = ({ children, isOpen, onClose }) => {
  if (typeof window === "undefined") {
    return null;
  }

  const portalRoot = document.getElementById("portal");
  const element = document.createElement("div");

  const modalRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      portalRoot.appendChild(element);
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => (document.body.style.overflow = "auto");
  }, [element, isOpen, portalRoot]);

  useOnClickOutside(modalRef, onClose);
  useFocusFirstFocusable(modalRef, isOpen);

  return isOpen
    ? ReactDOM.createPortal(
        <div className="Modal__container">
          <div className="Modal__wrap">
            <div className="[ Modal__inner-wrap ] [ Content ]">
              <div className="Modal" ref={modalRef}>
                <Close
                  className="[ Modal__close-icon ] [ Color_darkBlue ]"
                  aria-label="Close"
                  onClick={onClose}
                />
                <div className="Modal__content">{children}</div>
              </div>
            </div>
          </div>
        </div>,
        element
      )
    : null;
};

export { Modal };
