import cn from "classnames";
import React from "react";
import { Tag } from "../Tag";
import "./styles.css";

const Tags = ({ tags, ulClassName, liClassName, except }) => {
  const atLeastOne = !!Object.values(tags).filter((value) => value).length;
  if (except) {
    delete tags[except];
  }

  const ulClassname = cn({
    Tags: !ulClassName,
    [ulClassName]: ulClassName,
  });

  const liClassname = cn({
    Tag: !liClassName,
    [liClassName]: liClassName,
  });

  return atLeastOne ? (
    <ul className={`[ ${ulClassname} ] [ Font_smallText ]`}>
      {Object.entries(tags).map(([tag, value], index) => {
        let classTag = `Tag_${tag}`;

        return (
          value && (
            <Tag
              className={cn(`[ ${liClassname} ]`, {
                [`[ ${classTag} ]`]: tag,
              })}
              tagName={tag}
              discoutValue={value}
              key={index}
            />
          )
        );
      })}
    </ul>
  ) : null;
};

export { Tags };
