import React from "react";

export const Arrow = (props) => {
  return (
    <svg
      {...props}
      width="5"
      height="8"
      viewBox="0 0 5 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1L4 4L1 7" stroke="#194068" strokeOpacity="0.48" />
    </svg>
  );
};
