import { graphql } from "gatsby";
import Link from "gatsby-link";
import React from "react";
import { Image } from "../Image";
import { Slider } from "../Slider";
import {
  SliderCard,
  SliderCardContent,
  SliderCardImage,
} from "../Slider/SliderCard";
import { Tags } from "./../ProductProduct/Tags";
import "./styles.css";

const ProductSimilarProducts = ({ title, products, category, slug }) => {
  return (
    <div className="ProductSimilarProducts_wrapper">
      <div className="[ ProductSimilarProducts ] [ Content md:Content_small ]">
        <div className=" [ Font_headerBig Color_darkBlue ] ">{title}</div>

        <Slider
          productPage={true}
          className="[ ProductSimilarProducts__slider ]"
        >
          {products
            .filter(
              (product) =>
                product.category === category && slug !== product.slug
            )
            .map(
              (
                {
                  photos,
                  price,
                  oldPrice,
                  name,
                  category,
                  additionalCriteria,
                  slug,
                },
                index
              ) => (
                <Link to={`/catalog/${slug}`} key={index}>
                  <SliderCard
                    className="[ ProductSimilarProducts__sliderCard ]"
                    classNameContent="[ ProductSimilarProducts__sliderCard__content  ]"
                  >
                    <SliderCardImage productPage={true}>
                      <Image
                        className="ProductSimilarProducts__cardImage"
                        image={photos[0]}
                        alt=""
                      />
                      {additionalCriteria.discount && (
                        <div className="[ ProductSimilarProducts__tagDiscount ] [ Font_smallText  ]">
                          {additionalCriteria.discount}
                        </div>
                      )}
                      <div className="ProductSimilarProducts__cardTags">
                        <Tags
                          ulClassName="ProductSimilarProducts__tags"
                          liClassName="ProductSimilarProducts__tag"
                          tags={additionalCriteria}
                          except="discount"
                        />
                      </div>
                    </SliderCardImage>
                    <SliderCardContent className="ProductSimilarProducts__cardContent">
                      <div className="[  ProductSimilarProducts__cardCategory ]">
                        {category}
                      </div>

                      <div className=" [ ProductSimilarProducts__cardName ] [ Font_textSemiBold Color_darkBlue ]">
                        {name}
                      </div>
                      <div className="ProductSimilarProducts__cardPrices">
                        {oldPrice && (
                          <div className="[ ProductSimilarProducts__cardOldPrice ] [ Font_headerSmallest  ]">
                            {oldPrice}
                          </div>
                        )}
                        <div className="[ ProductSimilarProducts__cardPrice ] [ Font_headerSmall  Color_blue ] ">
                          {price}
                        </div>
                      </div>
                    </SliderCardContent>
                  </SliderCard>
                </Link>
              )
            )}
        </Slider>
      </div>
    </div>
  );
};

ProductSimilarProducts.getLivePreviewData = ({ data, getAsset }) => ({
  similarProducts: {
    ...data.similarProducts,
  },
});

const productSimilarProductsQuery = graphql`
  fragment ProductSimilarProductsFragment on MarkdownRemarkFrontmatter {
    similarProducts {
      title
    }
  }
`;

export { ProductSimilarProducts, productSimilarProductsQuery };
