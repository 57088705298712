import React from "react";

const Close = (props) => {
  return (
    <svg viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M0.572941 0.000247999C0.4595 0.0002778 0.348645 0.0341487 0.254548 0.0975305C0.160452 0.160912 0.0873899 0.250925 0.0447028 0.356061C0.00201569 0.461196 -0.00835668 0.576678 0.0149117 0.687741C0.0381801 0.798804 0.094032 0.900403 0.175327 0.979547L3.19363 3.99878L0.175327 7.018C0.120598 7.07057 0.0769041 7.13352 0.0468036 7.20319C0.0167031 7.27285 0.000801386 7.34782 2.95169e-05 7.42371C-0.000742352 7.4996 0.0136313 7.57488 0.0423086 7.64515C0.0709858 7.71541 0.11339 7.77924 0.167039 7.83291C0.220687 7.88657 0.2845 7.92899 0.354742 7.95768C0.424983 7.98636 0.500241 8.00074 0.576107 7.99997C0.651973 7.9992 0.726923 7.98329 0.796566 7.95318C0.86621 7.92307 0.929148 7.87936 0.981693 7.82462L4 4.80539L7.0183 7.82462C7.07085 7.87936 7.13379 7.92307 7.20343 7.95318C7.27307 7.98329 7.34802 7.9992 7.42389 7.99997C7.49976 8.00074 7.57501 7.98636 7.64526 7.95768C7.7155 7.92899 7.77931 7.88658 7.83296 7.83291C7.88661 7.77925 7.92901 7.71541 7.95769 7.64515C7.98637 7.57488 8.00074 7.4996 7.99997 7.42371C7.9992 7.34782 7.98329 7.27285 7.95319 7.20319C7.92309 7.13352 7.8794 7.07056 7.82467 7.018L4.80636 3.99878L7.82467 0.979547C7.90707 0.899424 7.96335 0.796269 7.98614 0.683599C8.00893 0.570929 7.99716 0.454002 7.95237 0.34814C7.90758 0.242277 7.83186 0.152419 7.73515 0.0903405C7.63843 0.028262 7.52522 -0.00314052 7.41035 0.000247999C7.2622 0.00466372 7.12159 0.0665997 7.0183 0.172934L4 3.19216L0.981693 0.172934C0.928552 0.118291 0.865001 0.0748564 0.794795 0.0451963C0.724589 0.0155362 0.649152 0.000252443 0.572941 0.000247999Z" />
    </svg>
  );
};

export { Close };
