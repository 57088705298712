import { graphql } from "gatsby";
import React from "react";
import Helmet from "react-helmet";
import { Layout } from "../components/Layout";
import { ProductProduct } from "../components/ProductProduct";
import { ProductSimilarProducts } from "../components/ProductSimilarProducts";
import { ProductSystems } from "../components/ProductSystems";

const ProductPageTemplate = ({
  selectedProduct,
  products,
  layout,
  ...props
}) => {
  return (
    <>
      <Helmet>
        <title>{props.seo.title}</title>
        <meta name="Description" content={props.seo.description} />
        <meta name="keywords" content={props.seo.keywords} />
      </Helmet>

      <ProductProduct selectedProduct={selectedProduct} {...props.product} />
      <ProductSystems category={selectedProduct.category} {...props.systems} />
      <ProductSimilarProducts
        category={selectedProduct.category}
        slug={selectedProduct.slug}
        products={products}
        {...props.similarProducts}
      />
    </>
  );
};

ProductPageTemplate.getLivePreviewData = (props) => {
  return {
    ...props.data,
    ...Layout.getLivePreviewData(props),
    ...ProductProduct.getLivePreviewData(props),
    ...ProductSystems.getLivePreviewData(props),
    ...ProductSimilarProducts.getLivePreviewData(props),
  };
};

const ProductPage = ({ data, pageContext }) => {
  const { frontmatter } = data.markdownRemark;
  const { slug } = pageContext;
  const products = data.catalog.frontmatter.products;
  const selectedProduct = products.find((product) => product.slug === slug);

  return (
    <Layout
      {...data.layout.frontmatter}
      pageId={frontmatter.pageId}
      path={frontmatter.path}
    >
      <ProductPageTemplate
        layout={data.layout.frontmatter}
        {...frontmatter}
        selectedProduct={selectedProduct}
        products={products}
      />
    </Layout>
  );
};

const pageQuery = graphql`
  query ProductPageTemplate {
    layout: markdownRemark(frontmatter: { meta: { eq: "layout" } }) {
      frontmatter {
        ...LayoutFragment
      }
    }

    markdownRemark(frontmatter: { pageId: { eq: "Продукт" } }) {
      frontmatter {
        seo {
          title
          description
          keywords
        }

        templateKey
        ...ProductProductFragment
        ...ProductSystemsFragment
        ...ProductSimilarProductsFragment
      }
    }

    catalog: markdownRemark(
      frontmatter: { templateKey: { eq: "catalog-page" } }
    ) {
      frontmatter {
        seo {
          title
          description
          keywords
        }

        templateKey
        ...CatalogCatalogFragment
      }
    }
  }
`;

export default ProductPage;

export { pageQuery, ProductPageTemplate };
