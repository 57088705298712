import { graphql } from "gatsby";
import React from "react";
import business from "./images/business.png";
import house from "./images/house.png";
import kitchen from "./images/kitchen.png";
import { Solution } from "./Solution";
import "./styles.css";

const ProductSystems = ({
  buttonLabel,
  forHome,
  forKitchen,
  forBusiness,
  category,
}) => {
  return (
    <>
      {category === "Для бизнеса" && (
        <Solution
          className="ProductSystems__Solution"
          subtitle={forBusiness.title}
          link={forBusiness.link}
          background={business}
          buttonLabel={buttonLabel}
          name="Бизнес"
        />
      )}
      {category === "Для кухни" && (
        <Solution
          className="ProductSystems__Solution"
          subtitle={forKitchen.title}
          link={forKitchen.link}
          background={house}
          name="Кухня"
        />
      )}
      {category === "Для всего дома" && (
        <Solution
          className="ProductSystems__Solution"
          subtitle={forHome.title}
          link={forHome.link}
          background={kitchen}
          name="Дом"
        />
      )}
    </>
  );
};

ProductSystems.getLivePreviewData = ({ data, getAsset }) => ({
  systems: {
    ...data.systems,
    forHome: {
      ...data.systems.forHome,
      image: getAsset(data.systems.forHome.image).url,
    },
    forKitchen: {
      ...data.systems.forKitchen,
      image: getAsset(data.systems.forKitchen.image).url,
    },
    forBusiness: {
      ...data.systems.forBusiness,
      image: getAsset(data.systems.forBusiness.image).url,
    },
  },
});

const productSystemsQuery = graphql`
  fragment ProductSystemsFragment on MarkdownRemarkFrontmatter {
    systems {
      buttonLabel
      forHome {
        title
        link
        image {
          childImageSharp {
            fluid(maxWidth: 1504) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      forKitchen {
        title
        link
        image {
          childImageSharp {
            fluid(maxWidth: 1504) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      forBusiness {
        title
        link
        image {
          childImageSharp {
            fluid(maxWidth: 1504) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export { ProductSystems, productSystemsQuery };
